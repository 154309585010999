import React from "react";
import { Helmet } from "react-helmet-async";
import Providers from "./components/Providers";
import { getMetaTitle } from "@torch-ai-internal/react-display-components/lib/utils/meta";
import { ErrorBoundary } from "@torch-ai-internal/react-display-components/lib/components/Feedback/ErrorBoundary/ErrorBoundary";
import Router from "./components/Router";
import { useStyles } from "./styles";
import { appName } from "../../constants";
import AppBar from "./components/AppBar";

const App = (() => {
  const classes = useStyles();
  return (
    <Providers>
      <Helmet>
        <title>{getMetaTitle(undefined, appName)}</title>
      </Helmet>
      <div className={classes.root}>
        <AppBar />

        <section className={classes.contentWrap}>
          <div className={classes.content}>
            <ErrorBoundary>
              <Router />
            </ErrorBoundary>
          </div>
        </section>
      </div>
    </Providers>
  );
}) as React.FunctionComponent;
export default App;
