import React from "react";
import { Outlet, useParams } from "react-router-dom";
import { Provider as WorkspaceContext } from "./WorkspaceContext";

const WorkspaceRouter: React.FunctionComponent = () => {
  // const { id } = useParams<KnowledgeBaseRouteParams>();
  // TODO: Figure out the TS error with the above.
  const { id } = useParams();

  return (
    <WorkspaceContext id={id || ""}>
      <Outlet />
    </WorkspaceContext>
  );
};
export default WorkspaceRouter;
