import React, { Suspense } from "react";
import { Routes, Route } from "react-router-dom";
import { AppRouteHome, AppRouteKnowledge } from "../../../AppRoutes";
import PageLoading from "@torch-ai-internal/react-display-components/lib/components/Placeholders/PageLoading/PageLoading";
import KnowledgeBaseRouter from "../../Knowledge/Router";
import {
  KnowledgeBaseRouteForceGraph,
  KnowledgeBaseRouteHome,
  KnowledgeBaseRouteMap,
  KnowledgeBaseRouteNew,
  KnowledgeBaseRouteRawData,
  KnowledgeBaseRouteVisualizations,
} from "../../Knowledge/Routes";
import WorkspaceRouter from "../../Knowledge/components/Workspace/Router";
import VisualizationsRouter from "../../Knowledge/components/Workspace/components/Visualizations/VisualizationsRouter";
import { Provider as NewGraphProvider } from "../../Knowledge/components/New/NewGraphContext";

const KnowledgeBase = React.lazy(
  () => import("../../../components/Knowledge/components/Index/Page")
);
const KnowledgeBaseNew = React.lazy(
  () => import("../../../components/Knowledge/components/New/Page")
);

const KnowledgeBaseRawData = React.lazy(
  () =>
    import(
      "../../../components/Knowledge/components/Workspace/components/RawData/Page"
    )
);
const KnowledgeBaseForceGraph = React.lazy(
  () =>
    import(
      "../../../components/Knowledge/components/Workspace/components/Visualizations/ForceGraphView/Page"
    )
);
const KnowledgeBaseMap = React.lazy(
  () =>
    import(
      "../../../components/Knowledge/components/Workspace/components/Visualizations/MapView/Page"
    )
);

const Router = (() => (
  <Suspense fallback={<PageLoading />}>
    <Routes>
      <Route {...AppRouteHome} element={<KnowledgeBase />} />
      <Route {...AppRouteKnowledge} element={<KnowledgeBaseRouter />}>
        <Route
          path={`${KnowledgeBaseRouteHome.path}`}
          element={<KnowledgeBase />}
        />
        <Route element={<NewGraphProvider />}>
          <Route
            path={`${KnowledgeBaseRouteNew.path}`}
            element={<KnowledgeBaseNew />}
          />
        </Route>

        <Route
          path={`${KnowledgeBaseRouteVisualizations.path}`}
          element={<WorkspaceRouter />}
        >
          <Route
            path={`${KnowledgeBaseRouteRawData.path}`}
            element={<KnowledgeBaseRawData />}
          />
          <Route
            path={`${KnowledgeBaseRouteVisualizations.path}`}
            element={<VisualizationsRouter />}
          >
            <Route
              path={`${KnowledgeBaseRouteForceGraph.path}`}
              element={<KnowledgeBaseForceGraph />}
            />

            <Route
              path={`${KnowledgeBaseRouteMap.path}`}
              element={<KnowledgeBaseMap />}
            />
          </Route>
        </Route>
      </Route>
    </Routes>
  </Suspense>
)) as React.FunctionComponent;
export default Router;
