import React, { useCallback } from "react";
import { KnowledgeBaseGraph } from "../generated/types";
import { useSessionStorage } from "../hooks/useSessionStorage";
import uniqBy from "lodash/uniqBy";

type RecentKnowledgeGraphItem = Pick<KnowledgeBaseGraph, "id" | "displayName">;
export interface IRecentKnowledgeContext {
  items: RecentKnowledgeGraphItem[];
  addItem: (item: RecentKnowledgeGraphItem) => void;
  deleteItem: (id: RecentKnowledgeGraphItem["id"]) => void;
}
const RecentKnowledgeContext = React.createContext<IRecentKnowledgeContext>(
  // This is definitional only. The provider creates the real values
  {} as IRecentKnowledgeContext
);
RecentKnowledgeContext.displayName = "IRecentKnowledgeContext";
export default RecentKnowledgeContext;

interface Props {}
export const Provider: React.FunctionComponent<Props> = ({ children }) => {
  const [items, setItems] = useSessionStorage<IRecentKnowledgeContext["items"]>(
    "recentKnowledgeGraphs",
    []
  );

  const addItem: IRecentKnowledgeContext["addItem"] = useCallback(
    (item) => {
      setItems((previousItems) => {
        // Find graphs other than this one.
        const otherItems = previousItems.filter(
          (previousItem) => previousItem.id !== item.id
        );
        // uniqBy removes duplicates
        const deduplicatedItems = uniqBy(otherItems, "id");

        // Add this item to the top
        deduplicatedItems.unshift(item);
        // Return the top 5 for storage
        return deduplicatedItems.slice(0, 5);
      });
    },
    [setItems]
  );

  const deleteItem: IRecentKnowledgeContext["deleteItem"] = useCallback(
    (id) => {
      setItems((previousItems) =>
        previousItems.filter((item) => item.id !== id)
      );
    },
    [setItems]
  );

  return (
    <RecentKnowledgeContext.Provider
      value={{
        items,
        addItem,
        deleteItem,
      }}
    >
      {children}
    </RecentKnowledgeContext.Provider>
  );
};
