import React, {
  Dispatch,
  FormEventHandler,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from "react";
import { Outlet, useNavigate } from "react-router-dom";
import UserContext from "../../../../contexts/UserContext";
import {
  CloudProvider,
  KnowledgeBaseContentMap,
  KnowledgeBaseFile,
  KnowledgeGraphBuildMutationHookResult,
  KnowledgeGraphCreationSettingsQueryHookResult,
  KnowledgeGraphProcessFileMutationHookResult,
  KnowledgeGraphProcessFileMutationVariables,
  OntologyListItem,
  useGetOntologiesListQuery,
  useKnowledgeGraphBuildMutation,
  useKnowledgeGraphCreationSettingsQuery,
  useKnowledgeGraphProcessFileMutation,
  useKnowledgeGraphsQuery,
} from "../../../../generated/types";
import { KnowledgeBaseRouteRawData } from "../../Routes";
import { appId as appIdConstant } from "../../../../constants";

interface Interface {
  activeStep: number;
  stepLabels: string[];
  handleBack: () => void;
  handleReset: () => void;
  fileSelection: KnowledgeBaseFile;
  setFileSelection: Dispatch<SetStateAction<Interface["fileSelection"]>>;
  graphName: string;
  setGraphName: Dispatch<SetStateAction<Interface["graphName"]>>;
  selectedContentMap: KnowledgeBaseContentMap;
  setSelectedContentMap: Dispatch<
    SetStateAction<Interface["selectedContentMap"]>
  >;
  handleSetContentMap: FormEventHandler;
  ontologySelectList: OntologyListItem[] | undefined;
  selectedOntology: OntologyListItem;
  setSelectedOntology: Dispatch<SetStateAction<Interface["selectedOntology"]>>;
  handleSetOntology: FormEventHandler;
  onSubmit: FormEventHandler;
  handleOnClick: (event: any) => void;
  handleSetGraphName: (event: any) => void;
  knowledgeGraphCreatingSettingsQuery: KnowledgeGraphCreationSettingsQueryHookResult;
  buildGraphMutation: KnowledgeGraphBuildMutationHookResult[1];
  processFileMutation: KnowledgeGraphProcessFileMutationHookResult[1];
  processFileVariables: KnowledgeGraphProcessFileMutationVariables;
  initialValues: any;
}

export const NewGraphContext = React.createContext<Interface>({} as Interface);

interface NewGraphProps {}

export const Provider: React.FunctionComponent<NewGraphProps> = ({
  children,
}) => {
  const initialValues = {
    ontology: {
      bucket: "",
      provider: CloudProvider.Aws,
      folder: "",
      filename: "",
    },
    file: {
      filePath: "",
      url: "",
    },
    map: {
      id: "",
      displayName: "",
    },
  };

  const [activeStep, setActiveStep] = React.useState(0);
  // Stepper controls
  const stepLabels = [
    "Select file",
    "Select ontology",
    "Select content map",
    "Name graph",
    "Review",
  ];

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setSelectedOntology(initialValues.ontology);
    setFileSelection(initialValues.file);
    setSelectedContentMap(initialValues.map);
    setGraphName(new Date().toLocaleString());
    setActiveStep(0);
  };

  const [fileSelection, setFileSelection] = useState<KnowledgeBaseFile>(
    initialValues.file
  );
  const [graphName, setGraphName] = useState(new Date().toLocaleString());
  const [selectedContentMap, setSelectedContentMap] =
    useState<KnowledgeBaseContentMap>(initialValues.map);
  const [selectedOntology, setSelectedOntology] = useState<OntologyListItem>(
    initialValues.ontology
  );
  const [ontologySelectList, setOntologySelectList] = useState<
    OntologyListItem[]
  >([initialValues.ontology]);

  const ontologiesListVariables = {
    bucket: "nexus-ontology",
    provider: CloudProvider.Aws,
    folder: "",
  };

  const systemOntologiesQuery = useGetOntologiesListQuery({
    variables: ontologiesListVariables,
  });

  const customOntologiesQuery = useGetOntologiesListQuery({
    variables: {
      ...ontologiesListVariables,
      folder: "customOntologies",
    },
  });

  useEffect(() => {
    // getSelectList();
    if (!systemOntologiesQuery.data || !customOntologiesQuery.data) {
      return;
    }
    setOntologySelectList([
      ...(systemOntologiesQuery.data.ontologies?.getOntologiesList || []),
      ...(customOntologiesQuery.data.ontologies?.getOntologiesList || []),
    ]);
  }, [
    systemOntologiesQuery.data,
    customOntologiesQuery.data,
    setOntologySelectList,
  ]);

  const navigate = useNavigate();

  const { userQuery } = useContext(UserContext);

  const knowledgeGraphCreatingSettingsQuery =
    useKnowledgeGraphCreationSettingsQuery();
  const [processFile, processFileMutation] =
    useKnowledgeGraphProcessFileMutation();
  const [buildGraph, buildGraphMutation] = useKnowledgeGraphBuildMutation();
  const knowledgeGraphsQuery = useKnowledgeGraphsQuery();

  const processFileVariables: KnowledgeGraphProcessFileMutationVariables = {
    graphName: "",
    filePath: "",
    url: "",
    mapId: "",
    ontology: "",
  };

  const onSubmit: FormEventHandler = async (event) => {
    event.preventDefault();

    if (activeStep < stepLabels.length - 1) {
      handleNext();
      return;
    }

    // If not let's start extraction!
    const graphNameCleaned: string = graphName.trim() || "";
    if (!graphNameCleaned.length) {
      return;
    }

    const processResult = await processFile({
      variables: {
        appId: appIdConstant,
        graphName: graphNameCleaned,
        filePath: fileSelection.filePath,
        url: fileSelection.url || "",
        mapId: selectedContentMap?.id || "",
        ontology: selectedOntology?.filename || "",
      },
    });
    if (!processResult.data?.knowledgeBase?.processFile?.id) {
      throw new Error("Processing failed");
    }

    // new mutation to hit the build endpoint
    buildGraph({
      variables: { id: processResult.data.knowledgeBase.processFile.id },
    });
    if (buildGraphMutation.error) {
      console.error(buildGraphMutation.error);
    }
    knowledgeGraphsQuery.refetch();
    // Redirect to raw data
    navigate(
      KnowledgeBaseRouteRawData.path!.replace(
        ":id",
        processResult.data?.knowledgeBase?.processFile?.id
      )
    );
  };

  const handleOnClick: FormEventHandler = (event) => {
    setGraphName(
      `${new Date().toLocaleString()} (${event.currentTarget.textContent}) - ${
        // Gets the initials of the logged-in user's name
        userQuery.data?.self?.displayName?.match(/\b\w/g)?.join("") || "Guest"
      }`
    );
    setFileSelection({
      filePath: event.currentTarget.textContent || "",
      url: event.currentTarget.getAttribute("data-url"),
    });
  };

  const handleSetGraphName: React.ChangeEventHandler<
    HTMLTextAreaElement | HTMLInputElement
  > = (event) => {
    setGraphName(event.currentTarget.value);
  };

  const handleSetOntology: FormEventHandler = (event) => {
    const ontology = ontologySelectList.find(
      (ontology) => ontology.filename === event.currentTarget.textContent
    );

    if (!ontology) {
      return;
    }

    setSelectedOntology(ontology);
  };

  const handleSetContentMap: FormEventHandler = (event) => {
    const contentMap =
      knowledgeGraphCreatingSettingsQuery.data?.knowledgeBase?.contentMapList?.find(
        (contentMap) =>
          contentMap.displayName === event.currentTarget.textContent
      );
    if (!contentMap) {
      return;
    }
    setSelectedContentMap(contentMap);
  };

  return (
    <NewGraphContext.Provider
      value={{
        activeStep,
        stepLabels,
        handleBack,
        handleReset,
        fileSelection,
        setFileSelection,
        graphName,
        setGraphName,
        selectedContentMap,
        setSelectedContentMap,
        handleSetContentMap,
        ontologySelectList,
        selectedOntology,
        setSelectedOntology,
        handleSetOntology,
        onSubmit,
        handleOnClick,
        handleSetGraphName,
        knowledgeGraphCreatingSettingsQuery,
        buildGraphMutation,
        processFileMutation,
        processFileVariables,
        initialValues,
      }}
    >
      {children}
      <Outlet />
    </NewGraphContext.Provider>
  );
};
