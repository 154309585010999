import { useState, useEffect } from "react";
import useWindowEvent from "./useWindowEvent";

export const useSessionStorage = <T extends unknown>(
  key: string,
  defaultValue: T
) => {
  // initialize the value from sessionStorage
  const [currentValue, setCurrentValue] = useState<T>(() => {
    const value = sessionStorage.getItem(key);
    if (!value) {
      return defaultValue;
    }

    return JSON.parse(value);
  });

  // set up the event listener
  useWindowEvent("storage", (e: StorageEvent) => {
    if (
      e.storageArea === sessionStorage &&
      e.key === key &&
      e.newValue !== currentValue
    ) {
      if (!e.newValue) {
        setCurrentValue(defaultValue);
        return;
      }
      setCurrentValue(JSON.parse(e.newValue));
    }
  });

  useEffect(() => {
    if (!currentValue) {
      sessionStorage.removeItem(key);
      return;
    }
    sessionStorage.setItem(key, JSON.stringify(currentValue));
  }, [key, currentValue]);

  // use as const to tell TypeScript this is a tuple
  return [currentValue, setCurrentValue] as const;
};
