import React, { useContext, useState } from "react";
import AppBarDisplay from "@torch-ai-internal/react-display-components/lib/components/App/AppBar/AppBar";
import AppBarBrandName from "@torch-ai-internal/react-display-components/lib/components/App/AppBarBrandName/AppBarBrandName";
import AppBarLogo from "@torch-ai-internal/react-display-components/lib/components/App/AppBarLogo/AppBarLogo";
import imageLogo from "../../../assets/app-bar-logo.png";
import imageBrandName from "../../../assets/app-bar-brand-name.png";
import { appName } from "../../../constants";
import UserContext from "../../../contexts/UserContext";
import { AppRouteHome, AppRouteLogout } from "../../../AppRoutes";

interface Props {}

const AppBar: React.FunctionComponent<Props> = () => {
  const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(false);
  const { userQuery } = useContext(UserContext);

  return (
    <AppBarDisplay
      user={{
        email: userQuery.data?.self?.email || undefined,
        isLoading: userQuery.loading,
        name: userQuery.data?.self?.displayName || "Guest",
      }}
      appName={appName}
      logo={<AppBarLogo src={imageLogo} />}
      brandName={<AppBarBrandName src={imageBrandName} />}
      isDrawerOpen={isDrawerOpen}
      setIsDrawerOpen={setIsDrawerOpen}
      urls={{
        home: AppRouteHome.path,
        logout: AppRouteLogout.path,
        userProfile: "/user",
      }}
    />
  );
};

export default AppBar;
