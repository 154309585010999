import { useEffect, useRef } from "react";

interface EventHandler<T extends Event = Event> {
  (e: T): void;
}

interface WindowEventHook {
  <K extends keyof WindowEventMap>(
    eventName: K,
    handler: EventHandler<WindowEventMap[K]>
  ): void;
}

const useWindowEvent: WindowEventHook = (eventName, handler) => {
  // optimization: using useRef here helps us guarantee that this function is
  // is only mutated during effect lifecycles, adding some assurance that the
  // function invoked by the event listener is the same function passed to the
  // hook.
  const handlerRef = useRef<typeof handler>();

  useEffect(() => {
    handlerRef.current = handler;
  }, [handler]);

  useEffect(() => {
    const eventListener: typeof handler = (event) => {
      if (!handlerRef.current) {
        return;
      }
      handlerRef.current(event);
    };
    window.addEventListener(eventName, eventListener);

    return () => window.removeEventListener(eventName, eventListener);
  }, [eventName, handler]);
};

export default useWindowEvent;
