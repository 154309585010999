import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** The `JSON` scalar type represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSON: any;
};

export enum CloudProvider {
  Aws = 'AWS',
  GoogleCloudPlatform = 'GoogleCloudPlatform'
}

export type Edge = IDocument & IRelationDirection & {
  __typename?: 'Edge';
  /** Unique identifier for the resource across all collections */
  _from: Scalars['ID'];
  /** Unique identifier for the resource across all collections */
  _id?: Maybe<Scalars['ID']>;
  /** Unique identifier for the resource within its collection */
  _key?: Maybe<Scalars['ID']>;
  /** Unique identifier for revision */
  _rev?: Maybe<Scalars['String']>;
  /** Unique identifier for the resource across all collections */
  _to: Scalars['ID'];
};

export type Geography = {
  __typename?: 'Geography';
  /** The center of the geography */
  center?: Maybe<GeographyCoordinates>;
};

export type GeographyCoordinates = {
  __typename?: 'GeographyCoordinates';
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
};

export type GeographyCoordinatesInput = {
  latitude?: InputMaybe<Scalars['Float']>;
  longitude?: InputMaybe<Scalars['Float']>;
};

export type GeographyInput = {
  center?: InputMaybe<GeographyCoordinatesInput>;
};

export type ICreated = {
  /** ISO date time string for the time this resource was created */
  createdAt?: Maybe<Scalars['String']>;
  /** Unique identifier for users that created this resource */
  createdBy?: Maybe<Scalars['ID']>;
};

export type IDisplayImage = {
  /** A renderedable source safe to display in HTML context */
  displayImageUrl?: Maybe<Scalars['String']>;
};

export type IDisplayName = {
  /** A preformatted display name safe to display in HTML context */
  displayName?: Maybe<Scalars['String']>;
};

export type IDocument = {
  /** Unique identifier for the resource across all collections */
  _id?: Maybe<Scalars['ID']>;
  /** Unique identifier for the resource within its collection */
  _key?: Maybe<Scalars['ID']>;
  /** Unique identifier for revision */
  _rev?: Maybe<Scalars['String']>;
};

export type IKnowledgeBaseAttribution = {
  /** Another fancy term for 'the graph's Id' */
  attribution: Scalars['ID'];
};

export type IKnowledgeBaseConfidence = {
  /** The confidence of this item. Range is 0-1. */
  confidence?: Maybe<Scalars['Float']>;
};

export type IKnowledgeBaseDomain = {
  /** Domain repesents a source of data we could collect such as News, Dark net, or Social */
  domain: Scalars['ID'];
};

export type IKnowledgeBaseOntologyEntity = {
  /** What the type of this entity is */
  entityType: Scalars['String'];
};

export type IRelationDirection = {
  /** Unique identifier for the resource across all collections */
  _from?: Maybe<Scalars['ID']>;
  /** Unique identifier for the resource across all collections */
  _to?: Maybe<Scalars['ID']>;
};

export type IUpdated = {
  /** ISO date time string for the time this resource was created */
  updatedAt?: Maybe<Scalars['String']>;
  /** Unique identifier for users that created this resource */
  updatedBy?: Maybe<Scalars['ID']>;
};

export type KnowledgeBase = {
  __typename?: 'KnowledgeBase';
  availableDomains?: Maybe<Array<KnowledgeBaseDomain>>;
  /** Returns a list of available files to process */
  availableFiles?: Maybe<Array<KnowledgeBaseFile>>;
  /** A list of content map objects */
  contentMapList?: Maybe<Array<KnowledgeBaseContentMap>>;
  /** Returns the raw data for a specific graph that was stored after processing the file */
  getRawData?: Maybe<KnowledgeBaseRawData>;
  /** Returns audit data for a domain belonging to a graph */
  graphAudit?: Maybe<KnowledgeBaseGraphAudit>;
  /** Returns vertex and links for a graph across all selected domains and ontologies for a given Graph with an optional center */
  graphContents?: Maybe<KnowledgeBaseGraphLayerContents>;
  /** Returns metadata about a knowledge graphs structure */
  graphStructure?: Maybe<KnowledgeBaseGraphStructure>;
  /** Returns detailed statistics and raw data for a target datum within a graph relative to the center datum */
  graphVertexAnalysis?: Maybe<KnowledgeBaseGraphVertexAnalysis>;
  /** Returns all graphs for the entire account group */
  graphs?: Maybe<Array<KnowledgeBaseGraph>>;
};


export type KnowledgeBaseGetRawDataArgs = {
  id: Scalars['ID'];
};


export type KnowledgeBaseGraphAuditArgs = {
  attribution: Scalars['ID'];
  domain: Scalars['ID'];
};


export type KnowledgeBaseGraphContentsArgs = {
  center?: InputMaybe<Scalars['ID']>;
  entityIdList?: InputMaybe<Array<Scalars['String']>>;
  id: Scalars['ID'];
  in?: InputMaybe<Scalars['Int']>;
  knowledgeType: KnowledgeType;
};


export type KnowledgeBaseGraphStructureArgs = {
  id: Scalars['ID'];
};


export type KnowledgeBaseGraphVertexAnalysisArgs = {
  id: Scalars['ID'];
  sourceId?: InputMaybe<Scalars['ID']>;
  targetId: Scalars['ID'];
  targetType: KnowledgeBaseGraphLayerContentType;
};

export type KnowledgeBaseContentMap = IDisplayName & {
  __typename?: 'KnowledgeBaseContentMap';
  /** A preformatted display name safe to display in HTML context */
  displayName?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
};

/** A knowledge domain repesents a source of data we could collect such as News, Dark net, or Social */
export type KnowledgeBaseDomain = IDisplayName & {
  __typename?: 'KnowledgeBaseDomain';
  /** A preformatted display name safe to display in HTML context */
  displayName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
};

/** Basic information on a file available for processing */
export type KnowledgeBaseFile = {
  __typename?: 'KnowledgeBaseFile';
  filePath: Scalars['String'];
  url?: Maybe<Scalars['String']>;
};

export type KnowledgeBaseGraph = IDisplayName & {
  __typename?: 'KnowledgeBaseGraph';
  /** The id of the application that created the graph used for filtering, i.e. the-matrix, or airwolf */
  appId?: Maybe<Scalars['String']>;
  /** ISO date time String for the time this graph was saved */
  createdAt: Scalars['String'];
  /** A preformatted display name safe to display in HTML context */
  displayName: Scalars['String'];
  domainStatuses?: Maybe<Array<KnowledgeBaseGraphDomainStatuses>>;
  id: Scalars['ID'];
};

export type KnowledgeBaseGraphAudit = IKnowledgeBaseAttribution & IKnowledgeBaseDomain & {
  __typename?: 'KnowledgeBaseGraphAudit';
  attribution: Scalars['ID'];
  audit?: Maybe<Scalars['JSON']>;
  domain: Scalars['ID'];
};

export enum KnowledgeBaseGraphDomainCollectionState {
  Completed = 'Completed',
  Error = 'Error',
  Processing = 'Processing',
  Queued = 'Queued'
}

export type KnowledgeBaseGraphDomainStatuses = IDisplayName & {
  __typename?: 'KnowledgeBaseGraphDomainStatuses';
  count: Scalars['Int'];
  /** A preformatted display name safe to display in HTML context */
  displayName: Scalars['String'];
  status?: Maybe<KnowledgeBaseGraphDomainCollectionState>;
};

export enum KnowledgeBaseGraphLayerContentType {
  Link = 'Link',
  Revision = 'Revision',
  Vertex = 'Vertex'
}

export type KnowledgeBaseGraphLayerContents = {
  __typename?: 'KnowledgeBaseGraphLayerContents';
  id: Scalars['ID'];
  knowledgeType: KnowledgeType;
  links?: Maybe<Array<KnowledgeBaseGraphLink>>;
  revisions?: Maybe<Array<KnowledgeBaseGraphRevision>>;
  vertexes?: Maybe<Array<KnowledgeBaseGraphVertex>>;
};

/** A link within knowledge within a graph from one KnowledgeBaseGraphVertex to another */
export type KnowledgeBaseGraphLink = IKnowledgeBaseConfidence & IKnowledgeBaseDomain & {
  __typename?: 'KnowledgeBaseGraphLink';
  /** The confidence of this item. Range is 0-1. */
  confidence?: Maybe<Scalars['Float']>;
  /** Domain repesents a source of data we could collect such as News, Dark net, or Social */
  domain: Scalars['ID'];
  /** The KnowledgeBaseGraphVertex.id on the source of this link */
  from: Scalars['ID'];
  id?: Maybe<Scalars['ID']>;
  relationship?: Maybe<Scalars['String']>;
  /** The KnowledgeBaseGraphVertex.id on the target of this link */
  to: Scalars['ID'];
  type: KnowledgeBaseGraphLinkType;
};

export enum KnowledgeBaseGraphLinkType {
  Child = 'Child',
  Cross = 'Cross',
  Revision = 'Revision'
}

export type KnowledgeBaseGraphRevision = ICreated & {
  __typename?: 'KnowledgeBaseGraphRevision';
  /** ISO date time string for the time this resource was created */
  createdAt?: Maybe<Scalars['String']>;
  /** Unique identifier for users that created this resource */
  createdBy?: Maybe<Scalars['ID']>;
  id?: Maybe<Scalars['ID']>;
  /** A description of what actions took place that created this revision */
  message: Scalars['String'];
};

export type KnowledgeBaseGraphStructure = IDisplayName & {
  __typename?: 'KnowledgeBaseGraphStructure';
  /** dcsa-sf86 */
  bucket?: Maybe<Scalars['String']>;
  /** 2021-07-28T00:24Z */
  createdDate?: Maybe<Scalars['String']>;
  /** A preformatted display name safe to display in HTML context */
  displayName?: Maybe<Scalars['String']>;
  /** sf86Extracts/6c47114e-3d55-4dab-84b8-f9858139e836 */
  extract?: Maybe<Scalars['String']>;
  /** https://dcsa-sf86.s3.us-east-2.amazonaws.com/BillieBee.xml */
  fileURL?: Maybe<Scalars['String']>;
  /** BillieBee.xml */
  filename?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  institutions: Array<KnowledgeBaseGraphStructureInstitution>;
  /** 2021-07-28T00:24Z */
  lastModified?: Maybe<Scalars['String']>;
  persons: Array<KnowledgeBaseGraphStructurePerson>;
  /** AWS */
  provider?: Maybe<Scalars['String']>;
};

export type KnowledgeBaseGraphStructureDomain = IDisplayName & IDocument & {
  __typename?: 'KnowledgeBaseGraphStructureDomain';
  /** Unique identifier for the resource across all collections */
  _id?: Maybe<Scalars['ID']>;
  /** Unique identifier for the resource within its collection */
  _key?: Maybe<Scalars['ID']>;
  /** Unique identifier for revision */
  _rev?: Maybe<Scalars['String']>;
  /** A preformatted display name safe to display in HTML context */
  displayName?: Maybe<Scalars['String']>;
  entityTypes?: Maybe<Array<KnowledgeBaseGraphStructureDomainEntityType>>;
  /** The original id for the graph as provided by knowledge services */
  graphId?: Maybe<Scalars['ID']>;
  /** The original id as provided by the knowledge services */
  id: Scalars['ID'];
  ontology?: Maybe<Scalars['String']>;
  source?: Maybe<Scalars['String']>;
  status?: Maybe<KnowledgeBaseGraphDomainCollectionState>;
};

export type KnowledgeBaseGraphStructureDomainEntityType = {
  __typename?: 'KnowledgeBaseGraphStructureDomainEntityType';
  count?: Maybe<Scalars['Int']>;
  displayName?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

export type KnowledgeBaseGraphStructureDomainInput = {
  /** Unique identifier for the resource within its collection */
  _key?: InputMaybe<Scalars['ID']>;
  /** A preformatted display name safe to display in HTML context */
  displayName?: InputMaybe<Scalars['String']>;
  /** The original id for the graph as provided by knowledge services */
  graphId: Scalars['ID'];
  /** The original id as provided by the knowledge services */
  id: Scalars['ID'];
};

export type KnowledgeBaseGraphStructureInstitution = {
  __typename?: 'KnowledgeBaseGraphStructureInstitution';
  domains: Array<KnowledgeBaseGraphStructureDomain>;
  entityId?: Maybe<Scalars['ID']>;
};

export type KnowledgeBaseGraphStructurePerson = {
  __typename?: 'KnowledgeBaseGraphStructurePerson';
  domains: Array<KnowledgeBaseGraphStructureDomain>;
  entityId?: Maybe<Scalars['ID']>;
};

/** An individual datum within knowledge within a graph */
export type KnowledgeBaseGraphVertex = IDisplayName & IKnowledgeBaseAttribution & IKnowledgeBaseConfidence & IKnowledgeBaseDomain & IKnowledgeBaseOntologyEntity & {
  __typename?: 'KnowledgeBaseGraphVertex';
  /** Attributes belonging to this instance of an entity type */
  attributes?: Maybe<Scalars['JSON']>;
  attribution: Scalars['ID'];
  /** The confidence of this item. Range is 0-1. */
  confidence?: Maybe<Scalars['Float']>;
  /** A preformatted display name safe to display in HTML context */
  displayName?: Maybe<Scalars['String']>;
  domain: Scalars['ID'];
  entityType: Scalars['String'];
  /** Coordinate and geography details for this vertex */
  geography?: Maybe<Geography>;
  id?: Maybe<Scalars['ID']>;
};

/** Detailed statistics and raw data for a target datum within a graph relative to the center datum */
export type KnowledgeBaseGraphVertexAnalysis = {
  __typename?: 'KnowledgeBaseGraphVertexAnalysis';
  /** The unique id for the graph to query */
  id: Scalars['ID'];
  /** The maximum confidence of this item through any available routes. Range is 0-1. */
  maxConfidence?: Maybe<Scalars['Float']>;
  /** Details on every path available from the source to the target */
  routes: Scalars['JSON'];
  source: KnowledgeBaseGraphVertexAnalysisSource;
  target: KnowledgeBaseGraphVertexAnalysisTarget;
};

export type KnowledgeBaseGraphVertexAnalysisSource = IDisplayName & IKnowledgeBaseOntologyEntity & {
  __typename?: 'KnowledgeBaseGraphVertexAnalysisSource';
  /** A preformatted display name safe to display in HTML context */
  displayName?: Maybe<Scalars['String']>;
  /** Ontology of this item, such as a Entity, Article, or Event */
  entityType: Scalars['String'];
  id?: Maybe<Scalars['ID']>;
  rawData: Scalars['JSON'];
};

export type KnowledgeBaseGraphVertexAnalysisTarget = IDisplayName & IKnowledgeBaseDomain & IKnowledgeBaseOntologyEntity & {
  __typename?: 'KnowledgeBaseGraphVertexAnalysisTarget';
  /** A preformatted display name safe to display in HTML context */
  displayName?: Maybe<Scalars['String']>;
  /** Domain repesents a source of data we could collect such as News, Dark net, or Social */
  domain: Scalars['ID'];
  /** Ontology of this item, such as a Entity, Article, or Event */
  entityType: Scalars['String'];
  id?: Maybe<Scalars['ID']>;
  rawData: Scalars['JSON'];
  type: KnowledgeBaseGraphLayerContentType;
};

export type KnowledgeBaseMutations = {
  __typename?: 'KnowledgeBaseMutations';
  /** Builds a knowledge graph - The id of the graph to process */
  build?: Maybe<KnowledgeBaseSuccess>;
  /** Deletes a knowledge graph by id */
  delete?: Maybe<KnowledgeBaseSuccess>;
  deleteStructureDomain?: Maybe<KnowledgeBaseGraphStructureDomain>;
  /** Extracts data from the sf-86 file and returns a JSON object */
  processFile?: Maybe<KnowledgeBaseRawData>;
  saveStructureDomain?: Maybe<KnowledgeBaseGraphStructureDomain>;
};


export type KnowledgeBaseMutationsBuildArgs = {
  id: Scalars['ID'];
};


export type KnowledgeBaseMutationsDeleteArgs = {
  id: Scalars['ID'];
};


export type KnowledgeBaseMutationsDeleteStructureDomainArgs = {
  key: Scalars['ID'];
};


export type KnowledgeBaseMutationsProcessFileArgs = {
  appId?: InputMaybe<Scalars['String']>;
  filePath: Scalars['String'];
  graphName: Scalars['String'];
  mapId?: InputMaybe<Scalars['String']>;
  ontology?: InputMaybe<Scalars['String']>;
  url: Scalars['String'];
};


export type KnowledgeBaseMutationsSaveStructureDomainArgs = {
  domain?: InputMaybe<KnowledgeBaseGraphStructureDomainInput>;
};

/** Extracted file data after sf-86 processing */
export type KnowledgeBaseRawData = {
  __typename?: 'KnowledgeBaseRawData';
  fileData: Scalars['JSON'];
  id: Scalars['ID'];
};

export type KnowledgeBaseSuccess = {
  __typename?: 'KnowledgeBaseSuccess';
  result: Scalars['JSON'];
  success: Scalars['Boolean'];
};

export enum KnowledgeType {
  Institutions = 'institutions',
  Persons = 'persons'
}

export type Mutation = {
  __typename?: 'Mutation';
  knowledgeBase?: Maybe<KnowledgeBaseMutations>;
  nexus?: Maybe<NexusMutations>;
};

export type NexusActorModel = IDisplayName & {
  __typename?: 'NexusActorModel';
  /** A preformatted display name safe to display in HTML context */
  displayName?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
};

export type NexusActorModelInput = {
  /** A preformatted display name safe to display in HTML context */
  displayName?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
};

export type NexusMutations = {
  __typename?: 'NexusMutations';
  /** Adds a new actor model and returns the full model */
  addActorModel?: Maybe<NexusActorModel>;
};


export type NexusMutationsAddActorModelArgs = {
  model?: InputMaybe<NexusActorModelInput>;
};

export type NexusQueries = {
  __typename?: 'NexusQueries';
  /** Returns a list of available actor models */
  actorModels?: Maybe<Array<NexusActorModel>>;
};

export type Ontologies = {
  __typename?: 'Ontologies';
  /** Gets a list of available Ontologies */
  getOntologiesList?: Maybe<Array<OntologyListItem>>;
};


export type OntologiesGetOntologiesListArgs = {
  bucket: Scalars['String'];
  folder: Scalars['String'];
  provider: CloudProvider;
};

/** An array of available Ontology objects in requested folder */
export type OntologyListItem = {
  __typename?: 'OntologyListItem';
  bucket: Scalars['String'];
  filename: Scalars['String'];
  folder: Scalars['String'];
  provider: CloudProvider;
  success?: Maybe<Scalars['Boolean']>;
};

export type Query = {
  __typename?: 'Query';
  knowledgeBase?: Maybe<KnowledgeBase>;
  nexus?: Maybe<NexusQueries>;
  ontologies?: Maybe<Ontologies>;
  self?: Maybe<User>;
};

export type User = ICreated & IDisplayName & IDocument & IUpdated & {
  __typename?: 'User';
  /** Unique identifier for the resource across all collections */
  _id?: Maybe<Scalars['ID']>;
  /** Unique identifier for the resource within its collection */
  _key?: Maybe<Scalars['ID']>;
  /** Unique identifier for revision */
  _rev?: Maybe<Scalars['String']>;
  /** ISO date time string for the time this resource was created */
  createdAt?: Maybe<Scalars['String']>;
  /** Unique identifier for users that created this resource */
  createdBy?: Maybe<Scalars['ID']>;
  /** A preformatted name safe to display in any HTML context */
  displayName?: Maybe<Scalars['String']>;
  /** Email addresses */
  email?: Maybe<Scalars['String']>;
  /** Determines if a users is a service account supporting applications */
  isServiceAccount?: Maybe<Scalars['Boolean']>;
  /** ISO date time string for the time this resource was created */
  updatedAt?: Maybe<Scalars['String']>;
  /** Unique identifier for users that created this resource */
  updatedBy?: Maybe<Scalars['ID']>;
};

export type KnowledgeGraphsQueryVariables = Exact<{ [key: string]: never; }>;


export type KnowledgeGraphsQuery = { __typename?: 'Query', knowledgeBase?: { __typename?: 'KnowledgeBase', graphs?: Array<{ __typename?: 'KnowledgeBaseGraph', appId?: string | null | undefined, id: string, displayName: string, createdAt: string, domainStatuses?: Array<{ __typename?: 'KnowledgeBaseGraphDomainStatuses', status?: KnowledgeBaseGraphDomainCollectionState | null | undefined, displayName: string, count: number }> | null | undefined }> | null | undefined } | null | undefined };

export type KnowledgeGraphCreationSettingsQueryVariables = Exact<{ [key: string]: never; }>;


export type KnowledgeGraphCreationSettingsQuery = { __typename?: 'Query', knowledgeBase?: { __typename?: 'KnowledgeBase', availableFiles?: Array<{ __typename?: 'KnowledgeBaseFile', filePath: string, url?: string | null | undefined }> | null | undefined, contentMapList?: Array<{ __typename?: 'KnowledgeBaseContentMap', id: string, displayName?: string | null | undefined }> | null | undefined } | null | undefined };

export type KnowledgeGraphDeleteMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type KnowledgeGraphDeleteMutation = { __typename?: 'Mutation', knowledgeBase?: { __typename?: 'KnowledgeBaseMutations', delete?: { __typename?: 'KnowledgeBaseSuccess', success: boolean, result: any } | null | undefined } | null | undefined };

export type KnowledgeGraphProcessFileMutationVariables = Exact<{
  filePath: Scalars['String'];
  url: Scalars['String'];
  graphName: Scalars['String'];
  ontology?: InputMaybe<Scalars['String']>;
  mapId?: InputMaybe<Scalars['String']>;
  appId?: InputMaybe<Scalars['String']>;
}>;


export type KnowledgeGraphProcessFileMutation = { __typename?: 'Mutation', knowledgeBase?: { __typename?: 'KnowledgeBaseMutations', processFile?: { __typename?: 'KnowledgeBaseRawData', id: string, fileData: any } | null | undefined } | null | undefined };

export type KnowledgeGraphRawDataQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type KnowledgeGraphRawDataQuery = { __typename?: 'Query', knowledgeBase?: { __typename?: 'KnowledgeBase', getRawData?: { __typename?: 'KnowledgeBaseRawData', id: string, fileData: any } | null | undefined } | null | undefined };

export type KnowledgeGraphStructureQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type KnowledgeGraphStructureQuery = { __typename?: 'Query', knowledgeBase?: { __typename?: 'KnowledgeBase', graphStructure?: { __typename?: 'KnowledgeBaseGraphStructure', id: string, displayName?: string | null | undefined, createdDate?: string | null | undefined, lastModified?: string | null | undefined, institutions: Array<{ __typename?: 'KnowledgeBaseGraphStructureInstitution', entityId?: string | null | undefined, domains: Array<{ __typename?: 'KnowledgeBaseGraphStructureDomain', _key?: string | null | undefined, id: string, graphId?: string | null | undefined, status?: KnowledgeBaseGraphDomainCollectionState | null | undefined, displayName?: string | null | undefined, source?: string | null | undefined, ontology?: string | null | undefined, entityTypes?: Array<{ __typename?: 'KnowledgeBaseGraphStructureDomainEntityType', displayName?: string | null | undefined, count?: number | null | undefined, type?: string | null | undefined }> | null | undefined }> }> } | null | undefined } | null | undefined };

export type KnowledgeGraphContentsQueryVariables = Exact<{
  id: Scalars['ID'];
  knowledgeType: KnowledgeType;
}>;


export type KnowledgeGraphContentsQuery = { __typename?: 'Query', knowledgeBase?: { __typename?: 'KnowledgeBase', graphContents?: { __typename?: 'KnowledgeBaseGraphLayerContents', knowledgeType: KnowledgeType, id: string, vertexes?: Array<{ __typename?: 'KnowledgeBaseGraphVertex', id?: string | null | undefined, attribution: string, domain: string, confidence?: number | null | undefined, displayName?: string | null | undefined, entityType: string, attributes?: any | null | undefined, geography?: { __typename?: 'Geography', center?: { __typename?: 'GeographyCoordinates', latitude?: number | null | undefined, longitude?: number | null | undefined } | null | undefined } | null | undefined }> | null | undefined, links?: Array<{ __typename?: 'KnowledgeBaseGraphLink', id?: string | null | undefined, domain: string, confidence?: number | null | undefined, from: string, to: string, type: KnowledgeBaseGraphLinkType, relationship?: string | null | undefined }> | null | undefined, revisions?: Array<{ __typename?: 'KnowledgeBaseGraphRevision', id?: string | null | undefined, message: string, createdAt?: string | null | undefined, createdBy?: string | null | undefined }> | null | undefined } | null | undefined } | null | undefined };

export type KnowledgeGraphBuildMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type KnowledgeGraphBuildMutation = { __typename?: 'Mutation', knowledgeBase?: { __typename?: 'KnowledgeBaseMutations', build?: { __typename?: 'KnowledgeBaseSuccess', success: boolean, result: any } | null | undefined } | null | undefined };

export type KnowledgeGraphDomainAuditQueryVariables = Exact<{
  domain: Scalars['ID'];
  attribution: Scalars['ID'];
}>;


export type KnowledgeGraphDomainAuditQuery = { __typename?: 'Query', knowledgeBase?: { __typename?: 'KnowledgeBase', graphAudit?: { __typename?: 'KnowledgeBaseGraphAudit', domain: string, attribution: string, audit?: any | null | undefined } | null | undefined } | null | undefined };

export type GetOntologiesListQueryVariables = Exact<{
  bucket: Scalars['String'];
  folder: Scalars['String'];
  provider: CloudProvider;
}>;


export type GetOntologiesListQuery = { __typename?: 'Query', ontologies?: { __typename?: 'Ontologies', getOntologiesList?: Array<{ __typename?: 'OntologyListItem', bucket: string, provider: CloudProvider, filename: string, folder: string }> | null | undefined } | null | undefined };

export type SelfQueryVariables = Exact<{ [key: string]: never; }>;


export type SelfQuery = { __typename?: 'Query', self?: { __typename?: 'User', _id?: string | null | undefined, _key?: string | null | undefined, displayName?: string | null | undefined, email?: string | null | undefined } | null | undefined };


export const KnowledgeGraphsDocument = gql`
    query KnowledgeGraphs {
  knowledgeBase {
    graphs {
      appId
      id
      displayName
      createdAt
      domainStatuses {
        status
        displayName
        count
      }
    }
  }
}
    `;

/**
 * __useKnowledgeGraphsQuery__
 *
 * To run a query within a React component, call `useKnowledgeGraphsQuery` and pass it any options that fit your needs.
 * When your component renders, `useKnowledgeGraphsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useKnowledgeGraphsQuery({
 *   variables: {
 *   },
 * });
 */
export function useKnowledgeGraphsQuery(baseOptions?: Apollo.QueryHookOptions<KnowledgeGraphsQuery, KnowledgeGraphsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<KnowledgeGraphsQuery, KnowledgeGraphsQueryVariables>(KnowledgeGraphsDocument, options);
      }
export function useKnowledgeGraphsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<KnowledgeGraphsQuery, KnowledgeGraphsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<KnowledgeGraphsQuery, KnowledgeGraphsQueryVariables>(KnowledgeGraphsDocument, options);
        }
export type KnowledgeGraphsQueryHookResult = ReturnType<typeof useKnowledgeGraphsQuery>;
export type KnowledgeGraphsLazyQueryHookResult = ReturnType<typeof useKnowledgeGraphsLazyQuery>;
export type KnowledgeGraphsQueryResult = Apollo.QueryResult<KnowledgeGraphsQuery, KnowledgeGraphsQueryVariables>;
export const KnowledgeGraphCreationSettingsDocument = gql`
    query KnowledgeGraphCreationSettings {
  knowledgeBase {
    availableFiles {
      filePath
      url
    }
    contentMapList {
      id
      displayName
    }
  }
}
    `;

/**
 * __useKnowledgeGraphCreationSettingsQuery__
 *
 * To run a query within a React component, call `useKnowledgeGraphCreationSettingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useKnowledgeGraphCreationSettingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useKnowledgeGraphCreationSettingsQuery({
 *   variables: {
 *   },
 * });
 */
export function useKnowledgeGraphCreationSettingsQuery(baseOptions?: Apollo.QueryHookOptions<KnowledgeGraphCreationSettingsQuery, KnowledgeGraphCreationSettingsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<KnowledgeGraphCreationSettingsQuery, KnowledgeGraphCreationSettingsQueryVariables>(KnowledgeGraphCreationSettingsDocument, options);
      }
export function useKnowledgeGraphCreationSettingsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<KnowledgeGraphCreationSettingsQuery, KnowledgeGraphCreationSettingsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<KnowledgeGraphCreationSettingsQuery, KnowledgeGraphCreationSettingsQueryVariables>(KnowledgeGraphCreationSettingsDocument, options);
        }
export type KnowledgeGraphCreationSettingsQueryHookResult = ReturnType<typeof useKnowledgeGraphCreationSettingsQuery>;
export type KnowledgeGraphCreationSettingsLazyQueryHookResult = ReturnType<typeof useKnowledgeGraphCreationSettingsLazyQuery>;
export type KnowledgeGraphCreationSettingsQueryResult = Apollo.QueryResult<KnowledgeGraphCreationSettingsQuery, KnowledgeGraphCreationSettingsQueryVariables>;
export const KnowledgeGraphDeleteDocument = gql`
    mutation KnowledgeGraphDelete($id: ID!) {
  knowledgeBase {
    delete(id: $id) {
      success
      result
    }
  }
}
    `;
export type KnowledgeGraphDeleteMutationFn = Apollo.MutationFunction<KnowledgeGraphDeleteMutation, KnowledgeGraphDeleteMutationVariables>;

/**
 * __useKnowledgeGraphDeleteMutation__
 *
 * To run a mutation, you first call `useKnowledgeGraphDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useKnowledgeGraphDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [knowledgeGraphDeleteMutation, { data, loading, error }] = useKnowledgeGraphDeleteMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useKnowledgeGraphDeleteMutation(baseOptions?: Apollo.MutationHookOptions<KnowledgeGraphDeleteMutation, KnowledgeGraphDeleteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<KnowledgeGraphDeleteMutation, KnowledgeGraphDeleteMutationVariables>(KnowledgeGraphDeleteDocument, options);
      }
export type KnowledgeGraphDeleteMutationHookResult = ReturnType<typeof useKnowledgeGraphDeleteMutation>;
export type KnowledgeGraphDeleteMutationResult = Apollo.MutationResult<KnowledgeGraphDeleteMutation>;
export type KnowledgeGraphDeleteMutationOptions = Apollo.BaseMutationOptions<KnowledgeGraphDeleteMutation, KnowledgeGraphDeleteMutationVariables>;
export const KnowledgeGraphProcessFileDocument = gql`
    mutation KnowledgeGraphProcessFile($filePath: String!, $url: String!, $graphName: String!, $ontology: String, $mapId: String, $appId: String) {
  knowledgeBase {
    processFile(
      filePath: $filePath
      url: $url
      graphName: $graphName
      ontology: $ontology
      mapId: $mapId
      appId: $appId
    ) {
      id
      fileData
    }
  }
}
    `;
export type KnowledgeGraphProcessFileMutationFn = Apollo.MutationFunction<KnowledgeGraphProcessFileMutation, KnowledgeGraphProcessFileMutationVariables>;

/**
 * __useKnowledgeGraphProcessFileMutation__
 *
 * To run a mutation, you first call `useKnowledgeGraphProcessFileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useKnowledgeGraphProcessFileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [knowledgeGraphProcessFileMutation, { data, loading, error }] = useKnowledgeGraphProcessFileMutation({
 *   variables: {
 *      filePath: // value for 'filePath'
 *      url: // value for 'url'
 *      graphName: // value for 'graphName'
 *      ontology: // value for 'ontology'
 *      mapId: // value for 'mapId'
 *      appId: // value for 'appId'
 *   },
 * });
 */
export function useKnowledgeGraphProcessFileMutation(baseOptions?: Apollo.MutationHookOptions<KnowledgeGraphProcessFileMutation, KnowledgeGraphProcessFileMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<KnowledgeGraphProcessFileMutation, KnowledgeGraphProcessFileMutationVariables>(KnowledgeGraphProcessFileDocument, options);
      }
export type KnowledgeGraphProcessFileMutationHookResult = ReturnType<typeof useKnowledgeGraphProcessFileMutation>;
export type KnowledgeGraphProcessFileMutationResult = Apollo.MutationResult<KnowledgeGraphProcessFileMutation>;
export type KnowledgeGraphProcessFileMutationOptions = Apollo.BaseMutationOptions<KnowledgeGraphProcessFileMutation, KnowledgeGraphProcessFileMutationVariables>;
export const KnowledgeGraphRawDataDocument = gql`
    query KnowledgeGraphRawData($id: ID!) {
  knowledgeBase {
    getRawData(id: $id) {
      id
      fileData
    }
  }
}
    `;

/**
 * __useKnowledgeGraphRawDataQuery__
 *
 * To run a query within a React component, call `useKnowledgeGraphRawDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useKnowledgeGraphRawDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useKnowledgeGraphRawDataQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useKnowledgeGraphRawDataQuery(baseOptions: Apollo.QueryHookOptions<KnowledgeGraphRawDataQuery, KnowledgeGraphRawDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<KnowledgeGraphRawDataQuery, KnowledgeGraphRawDataQueryVariables>(KnowledgeGraphRawDataDocument, options);
      }
export function useKnowledgeGraphRawDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<KnowledgeGraphRawDataQuery, KnowledgeGraphRawDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<KnowledgeGraphRawDataQuery, KnowledgeGraphRawDataQueryVariables>(KnowledgeGraphRawDataDocument, options);
        }
export type KnowledgeGraphRawDataQueryHookResult = ReturnType<typeof useKnowledgeGraphRawDataQuery>;
export type KnowledgeGraphRawDataLazyQueryHookResult = ReturnType<typeof useKnowledgeGraphRawDataLazyQuery>;
export type KnowledgeGraphRawDataQueryResult = Apollo.QueryResult<KnowledgeGraphRawDataQuery, KnowledgeGraphRawDataQueryVariables>;
export const KnowledgeGraphStructureDocument = gql`
    query KnowledgeGraphStructure($id: ID!) {
  knowledgeBase {
    graphStructure(id: $id) {
      id
      displayName
      createdDate
      lastModified
      institutions {
        entityId
        domains {
          _key
          id
          graphId
          status
          displayName
          source
          ontology
          entityTypes {
            displayName
            count
            type
          }
        }
      }
    }
  }
}
    `;

/**
 * __useKnowledgeGraphStructureQuery__
 *
 * To run a query within a React component, call `useKnowledgeGraphStructureQuery` and pass it any options that fit your needs.
 * When your component renders, `useKnowledgeGraphStructureQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useKnowledgeGraphStructureQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useKnowledgeGraphStructureQuery(baseOptions: Apollo.QueryHookOptions<KnowledgeGraphStructureQuery, KnowledgeGraphStructureQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<KnowledgeGraphStructureQuery, KnowledgeGraphStructureQueryVariables>(KnowledgeGraphStructureDocument, options);
      }
export function useKnowledgeGraphStructureLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<KnowledgeGraphStructureQuery, KnowledgeGraphStructureQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<KnowledgeGraphStructureQuery, KnowledgeGraphStructureQueryVariables>(KnowledgeGraphStructureDocument, options);
        }
export type KnowledgeGraphStructureQueryHookResult = ReturnType<typeof useKnowledgeGraphStructureQuery>;
export type KnowledgeGraphStructureLazyQueryHookResult = ReturnType<typeof useKnowledgeGraphStructureLazyQuery>;
export type KnowledgeGraphStructureQueryResult = Apollo.QueryResult<KnowledgeGraphStructureQuery, KnowledgeGraphStructureQueryVariables>;
export const KnowledgeGraphContentsDocument = gql`
    query KnowledgeGraphContents($id: ID!, $knowledgeType: KnowledgeType!) {
  knowledgeBase {
    graphContents(id: $id, knowledgeType: $knowledgeType) {
      knowledgeType
      id
      vertexes {
        id
        attribution
        domain
        confidence
        displayName
        entityType
        geography {
          center {
            latitude
            longitude
          }
        }
        attributes
      }
      links {
        id
        domain
        confidence
        from
        to
        type
        relationship
      }
      revisions {
        id
        message
        createdAt
        createdBy
      }
    }
  }
}
    `;

/**
 * __useKnowledgeGraphContentsQuery__
 *
 * To run a query within a React component, call `useKnowledgeGraphContentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useKnowledgeGraphContentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useKnowledgeGraphContentsQuery({
 *   variables: {
 *      id: // value for 'id'
 *      knowledgeType: // value for 'knowledgeType'
 *   },
 * });
 */
export function useKnowledgeGraphContentsQuery(baseOptions: Apollo.QueryHookOptions<KnowledgeGraphContentsQuery, KnowledgeGraphContentsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<KnowledgeGraphContentsQuery, KnowledgeGraphContentsQueryVariables>(KnowledgeGraphContentsDocument, options);
      }
export function useKnowledgeGraphContentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<KnowledgeGraphContentsQuery, KnowledgeGraphContentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<KnowledgeGraphContentsQuery, KnowledgeGraphContentsQueryVariables>(KnowledgeGraphContentsDocument, options);
        }
export type KnowledgeGraphContentsQueryHookResult = ReturnType<typeof useKnowledgeGraphContentsQuery>;
export type KnowledgeGraphContentsLazyQueryHookResult = ReturnType<typeof useKnowledgeGraphContentsLazyQuery>;
export type KnowledgeGraphContentsQueryResult = Apollo.QueryResult<KnowledgeGraphContentsQuery, KnowledgeGraphContentsQueryVariables>;
export const KnowledgeGraphBuildDocument = gql`
    mutation KnowledgeGraphBuild($id: ID!) {
  knowledgeBase {
    build(id: $id) {
      success
      result
    }
  }
}
    `;
export type KnowledgeGraphBuildMutationFn = Apollo.MutationFunction<KnowledgeGraphBuildMutation, KnowledgeGraphBuildMutationVariables>;

/**
 * __useKnowledgeGraphBuildMutation__
 *
 * To run a mutation, you first call `useKnowledgeGraphBuildMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useKnowledgeGraphBuildMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [knowledgeGraphBuildMutation, { data, loading, error }] = useKnowledgeGraphBuildMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useKnowledgeGraphBuildMutation(baseOptions?: Apollo.MutationHookOptions<KnowledgeGraphBuildMutation, KnowledgeGraphBuildMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<KnowledgeGraphBuildMutation, KnowledgeGraphBuildMutationVariables>(KnowledgeGraphBuildDocument, options);
      }
export type KnowledgeGraphBuildMutationHookResult = ReturnType<typeof useKnowledgeGraphBuildMutation>;
export type KnowledgeGraphBuildMutationResult = Apollo.MutationResult<KnowledgeGraphBuildMutation>;
export type KnowledgeGraphBuildMutationOptions = Apollo.BaseMutationOptions<KnowledgeGraphBuildMutation, KnowledgeGraphBuildMutationVariables>;
export const KnowledgeGraphDomainAuditDocument = gql`
    query KnowledgeGraphDomainAudit($domain: ID!, $attribution: ID!) {
  knowledgeBase {
    graphAudit(domain: $domain, attribution: $attribution) {
      domain
      attribution
      audit
    }
  }
}
    `;

/**
 * __useKnowledgeGraphDomainAuditQuery__
 *
 * To run a query within a React component, call `useKnowledgeGraphDomainAuditQuery` and pass it any options that fit your needs.
 * When your component renders, `useKnowledgeGraphDomainAuditQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useKnowledgeGraphDomainAuditQuery({
 *   variables: {
 *      domain: // value for 'domain'
 *      attribution: // value for 'attribution'
 *   },
 * });
 */
export function useKnowledgeGraphDomainAuditQuery(baseOptions: Apollo.QueryHookOptions<KnowledgeGraphDomainAuditQuery, KnowledgeGraphDomainAuditQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<KnowledgeGraphDomainAuditQuery, KnowledgeGraphDomainAuditQueryVariables>(KnowledgeGraphDomainAuditDocument, options);
      }
export function useKnowledgeGraphDomainAuditLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<KnowledgeGraphDomainAuditQuery, KnowledgeGraphDomainAuditQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<KnowledgeGraphDomainAuditQuery, KnowledgeGraphDomainAuditQueryVariables>(KnowledgeGraphDomainAuditDocument, options);
        }
export type KnowledgeGraphDomainAuditQueryHookResult = ReturnType<typeof useKnowledgeGraphDomainAuditQuery>;
export type KnowledgeGraphDomainAuditLazyQueryHookResult = ReturnType<typeof useKnowledgeGraphDomainAuditLazyQuery>;
export type KnowledgeGraphDomainAuditQueryResult = Apollo.QueryResult<KnowledgeGraphDomainAuditQuery, KnowledgeGraphDomainAuditQueryVariables>;
export const GetOntologiesListDocument = gql`
    query getOntologiesList($bucket: String!, $folder: String!, $provider: CloudProvider!) {
  ontologies {
    getOntologiesList(bucket: $bucket, folder: $folder, provider: $provider) {
      bucket
      provider
      filename
      folder
    }
  }
}
    `;

/**
 * __useGetOntologiesListQuery__
 *
 * To run a query within a React component, call `useGetOntologiesListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOntologiesListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOntologiesListQuery({
 *   variables: {
 *      bucket: // value for 'bucket'
 *      folder: // value for 'folder'
 *      provider: // value for 'provider'
 *   },
 * });
 */
export function useGetOntologiesListQuery(baseOptions: Apollo.QueryHookOptions<GetOntologiesListQuery, GetOntologiesListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetOntologiesListQuery, GetOntologiesListQueryVariables>(GetOntologiesListDocument, options);
      }
export function useGetOntologiesListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetOntologiesListQuery, GetOntologiesListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetOntologiesListQuery, GetOntologiesListQueryVariables>(GetOntologiesListDocument, options);
        }
export type GetOntologiesListQueryHookResult = ReturnType<typeof useGetOntologiesListQuery>;
export type GetOntologiesListLazyQueryHookResult = ReturnType<typeof useGetOntologiesListLazyQuery>;
export type GetOntologiesListQueryResult = Apollo.QueryResult<GetOntologiesListQuery, GetOntologiesListQueryVariables>;
export const SelfDocument = gql`
    query Self {
  self {
    _id
    _key
    displayName
    email
  }
}
    `;

/**
 * __useSelfQuery__
 *
 * To run a query within a React component, call `useSelfQuery` and pass it any options that fit your needs.
 * When your component renders, `useSelfQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSelfQuery({
 *   variables: {
 *   },
 * });
 */
export function useSelfQuery(baseOptions?: Apollo.QueryHookOptions<SelfQuery, SelfQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SelfQuery, SelfQueryVariables>(SelfDocument, options);
      }
export function useSelfLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SelfQuery, SelfQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SelfQuery, SelfQueryVariables>(SelfDocument, options);
        }
export type SelfQueryHookResult = ReturnType<typeof useSelfQuery>;
export type SelfLazyQueryHookResult = ReturnType<typeof useSelfLazyQuery>;
export type SelfQueryResult = Apollo.QueryResult<SelfQuery, SelfQueryVariables>;