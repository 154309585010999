import React, { useState } from "react";

export interface IAppContext {
  isDrawerOpen: boolean;
  openDrawer: () => void;
  closeDrawer: () => void;
}

const AppContext = React.createContext<IAppContext>(
  // This is definitional only. The provider creates the real values
  {} as IAppContext
);
AppContext.displayName = "AppContext";

export default AppContext;

interface Props {}

export const AppContextProvider = (({ children }) => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const context: IAppContext = {
    isDrawerOpen,
    openDrawer: () => {
      setIsDrawerOpen(true);
    },
    closeDrawer: () => {
      setIsDrawerOpen(false);
    },
  };

  return <AppContext.Provider value={context}>{children}</AppContext.Provider>;
}) as React.FunctionComponent<Props>;
