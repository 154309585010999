import { ApolloClient, InMemoryCache, ApolloLink } from "@apollo/client";
import { createUploadLink } from "apollo-upload-client";
import { setContext } from "@apollo/client/link/context";

import AuthService from "./auth.service";

if (!process.env.REACT_APP_GRAPHQL_URL) {
  throw new Error("process.env.REACT_APP_GRAPHQL_URL is undefined");
}

// Connection to the API
const terminatingLink = createUploadLink({
  uri: process.env.REACT_APP_GRAPHQL_URL,
});
const authService = new AuthService();

const withToken = setContext(async () => {
  const authorization = await authService.getAuthorization();
  return { authorization };
});
const authMiddleware = new ApolloLink((operation, forward) => {
  const { authorization } = operation.getContext();
  operation.setContext(() => ({
    headers: {
      Authorization: authorization,
    },
  }));
  return forward(operation);
});
const link = ApolloLink.from([
  withToken,
  authMiddleware.concat(terminatingLink),
]);

// Cache implementation
const cache = new InMemoryCache({
  typePolicies: {
    Query: {
      fields: {
        // Merge our namespaces
        knowledgeBase: { merge: true },
        ontologies: { merge: true },
      },
    },
    KnowledgeBaseGraphContents: {
      keyFields: ["id"],
    },
    OntologyListItem: {
      keyFields: ["filename"],
    },
  },
});

// Create the apollo client
export const apolloClient = new ApolloClient({
  link,
  cache,
});
