import { RouteProps } from "react-router-dom";

export interface AppRouteProps extends RouteProps {
  title: string;
  // A set of properties for making a mappable list
  url?: string;
}

export const AppRouteHome: AppRouteProps = {
  title: `Home`,
  path: `/`,
};

export const AppRouteLogout: AppRouteProps = {
  title: `Logout`,
  path: `/logout`,
};

export const AppRouteKnowledge: AppRouteProps = {
  title: `Knowledge`,
  url: `/knowledge`,
  path: `/knowledge`,
};
