import {
  UserManager,
  WebStorageStateStore,
  User,
  UserManagerSettings,
} from "oidc-client";

export default class AuthService {
  private userManager: UserManager;

  constructor() {
    if (
      !process.env.REACT_APP_AUTHZ_SERVER_BASE ||
      !process.env.REACT_APP_BASE ||
      !process.env.REACT_APP_CLIENT_ID
    ) {
      throw new Error("Auth env vars not defined...");
    }
    const STS_DOMAIN: string = process.env.REACT_APP_AUTHZ_SERVER_BASE;
    const APP_URL: string = process.env.REACT_APP_BASE;
    const CLIENT_ID: string = process.env.REACT_APP_CLIENT_ID;

    const settings: UserManagerSettings = {
      userStore: new WebStorageStateStore({ store: window.localStorage }),
      authority: STS_DOMAIN,
      client_id: CLIENT_ID,
      redirect_uri: `${APP_URL}/callback.html`,
      automaticSilentRenew: true,
      silent_redirect_uri: `${APP_URL}/silent-renew.html`,
      response_type: "code",
      scope: "openid profile email offline",
      post_logout_redirect_uri: APP_URL,
      filterProtocolClaims: true,
    };

    this.userManager = new UserManager(settings);
  }

  public async getUser(): Promise<User | null> {
    return await this.userManager.getUser();
  }

  public async login(): Promise<void> {
    await this.userManager.signinRedirect();
  }

  public async logout(): Promise<void> {
    await this.userManager.signoutRedirect();
  }

  public async getAuthorization(): Promise<string> {
    const isLoggedIn = await this.isLoggedIn();
    if (!isLoggedIn) {
      await this.login();
    }
    const user = await this.userManager.getUser();
    if (!user) {
      throw new Error("Unable to get access token");
    }

    return `${user.token_type} ${user.access_token}`;
  }

  public async isLoggedIn(): Promise<boolean> {
    const user: User | null = await this.getUser();

    return user !== null && !user.expired;
  }
}
