import { AppRouteKnowledge, AppRouteProps } from "../../AppRoutes";

export interface KnowledgeBaseRouteParams {
  id: string;
}

export const KnowledgeBaseRouteHome: AppRouteProps = {
  title: "Knowledge base",
  url: `${AppRouteKnowledge.url}`,
  path: `${AppRouteKnowledge.path}`,
};

export const KnowledgeBaseRouteNew: AppRouteProps = {
  title: "New knowledge graph",
  url: `${AppRouteKnowledge.url}/new`,
  path: `${AppRouteKnowledge.path}/new`,
};

export const KnowledgeBaseRouteRawData: AppRouteProps = {
  title: "Raw data",
  url: `${AppRouteKnowledge.url}/:id/visualizations/raw-data`,
  path: `${AppRouteKnowledge.path}/:id/visualizations/raw-data`,
};

export const KnowledgeBaseRouteForceGraph: AppRouteProps = {
  title: "Molecule",
  url: `${AppRouteKnowledge.url}/:id/visualizations/workspace/graph`,
  path: `${AppRouteKnowledge.path}/:id/visualizations/workspace/graph`,
};

export const KnowledgeBaseRouteMap: AppRouteProps = {
  title: "Map",
  url: `${AppRouteKnowledge.url}/:id/visualizations/workspace/map`,
  path: `${AppRouteKnowledge.path}/:id/visualizations/workspace/map`,
};

export const KnowledgeBaseRouteVisualizations: AppRouteProps = {
  title: "Visualizations",
  url: `${AppRouteKnowledge.url}/:id/visualizations`,
  path: `${AppRouteKnowledge.path}/:id/visualizations`,
};
