import { colors } from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/core/styles";

const drawerWidth = 240;
export const navBarBackground = colors.blueGrey["900"];

export const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      display: "flex",
    },
    expansionControl: {
      position: "absolute",
      right: theme.spacing(-1.5),
      top: theme.spacing(8),
      zIndex: 2,
    },
    expansionControlIcon: {
      background: `${theme.palette.common.white} !important`,
      color: `${navBarBackground} !important`,
      border: `solid 2px ${navBarBackground}`,
      borderRadius: "50%",
      padding: "0 !important",
      transition: theme.transitions.create("transform", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    expansionControlIconOpen: {
      transform: "rotate(180deg)",
    },
    Logo: {
      marginRight: theme.spacing(2),
      height: theme.spacing(4),
      verticalAlign: "middle",
    },
    userContainer: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
      marginLeft: theme.spacing(-1),
    },
    userName: {
      flexGrow: 1,
    },
    userLogout: {},
    userLogoutButton: {
      padding: theme.spacing(1),
    },
    hide: {
      display: "none",
    },
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
      whiteSpace: "nowrap",
    },
    sideNavGridContainer: {
      overflowY: "auto",
      overflowX: "hidden",
    },
    sideNavMenuButton: {
      marginRight: theme.spacing(4),
    },
    sideNavHide: {
      display: "none",
    },
    sideNavDrawer: {
      width: drawerWidth,
      flexShrink: 0,
      whiteSpace: "nowrap",
      zIndex: theme.zIndex.drawer,
      backgroundColor: navBarBackground,
      color: theme.palette.common.white,
      padding: theme.spacing(2, 0),
      position: "relative",
    },
    sideDrawerOpen: {
      width: drawerWidth,
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    sideDrawerClose: {
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: theme.spacing(7) + 1,
      // [theme.breakpoints.up("sm")]: {
      //   width: theme.spacing(9) + 1,
      // },
    },
    sideNavListItems: {
      width: drawerWidth,
    },
    sideNavBottomList: {
      alignSelf: "flex-end",
    },
    toolbar: {
      display: "flex",
      flexGrow: 0,
      alignItems: "center",
      justifyContent: "flex-start",
      padding: theme.spacing(0, 2),
      // necessary for content to be below app bar
      ...theme.mixins.toolbar,
      backgroundColor: navBarBackground,
      color: theme.palette.common.white,
    },
    contentWrap: {
      display: "flex",
      flexDirection: "column",
      height: "100vh",
      overflow: "hidden",
      flexGrow: 1,
      position: "relative",
      zIndex: 1,
    },
    content: {
      flexGrow: 1,
      display: "flex",
      flexDirection: "column",
      overflowY: "auto",
    },
    expandIcon: {
      marginLeft: theme.spacing(2),
    },
    h100: {
      height: "100%",
    },
    flexGrow: {
      flexGrow: 1,
    },
  })
);
