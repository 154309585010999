import React, { useEffect, useContext } from "react";
import {
  KnowledgeGraphStructureLazyQueryHookResult,
  KnowledgeBaseGraph,
  useKnowledgeGraphStructureLazyQuery,
} from "../../../../generated/types";
import RecentKnowledgeContext from "../../../../contexts/RecentKnowledgeContext";

interface Interface {
  id: KnowledgeBaseGraph["id"];
  graphStructureQuery: KnowledgeGraphStructureLazyQueryHookResult[1];
}
export const WorkspaceContext = React.createContext<Interface>({} as Interface);

interface Props {
  id: KnowledgeBaseGraph["id"];
}
export const Provider: React.FunctionComponent<Props> = ({ id, children }) => {
  const [getGraphStructure, graphStructureQuery] =
    useKnowledgeGraphStructureLazyQuery();
  const { addItem: addRecentKnowledgeItem } = useContext(
    RecentKnowledgeContext
  );

  useEffect(() => {
    if (!id) {
      return;
    }

    console.info("Getting graph structure");
    getGraphStructure({ variables: { id } }).then((query) => {
      console.info("Getting graph structure complete");
      // Add the graph to the recent list
      const graph = query.data?.knowledgeBase?.graphStructure;
      if (!graph) {
        console.error("query.data?.knowledgeBase?.graphStructure is undefined");
        return;
      }

      addRecentKnowledgeItem({
        displayName: graph.displayName || graph.id,
        id: graph.id,
      });
    });
  }, [id, getGraphStructure, addRecentKnowledgeItem]);

  useEffect(() => {
    console.info("WorkspaceContext.id changed");
  }, [id]);

  useEffect(() => {
    console.info("getGraphStructure changed");
  }, [getGraphStructure]);

  useEffect(() => {
    console.info("addRecentKnowledgeItem changed");
  }, [addRecentKnowledgeItem]);

  return (
    <WorkspaceContext.Provider
      value={{
        graphStructureQuery: graphStructureQuery,
        id,
      }}
    >
      {children}
    </WorkspaceContext.Provider>
  );
};

type RGBColor = {
  r: number;
  g: number;
  b: number;
};
const colorMap: Record<string | "Other", RGBColor> = {
  Email: {
    r: 247,
    g: 148,
    b: 30,
  },
  Darkweb: {
    r: 47,
    g: 48,
    b: 63,
  },
  PAEI: {
    r: 84,
    g: 191,
    b: 102,
  },
  News: {
    r: 32,
    g: 35,
    b: 42,
  },
  Twitter: {
    r: 90,
    g: 148,
    b: 224,
  },
  Other: {
    r: 177,
    g: 177,
    b: 177,
  },
};

export const getDomainColorRGB = (domain: string): RGBColor =>
  colorMap[domain] || colorMap["Other"];

export const getDomainColorString = (domain: string): string => {
  const { r, g, b } = colorMap[domain] || colorMap["Other"];
  return `rgb(${r},${g},${b})`;
};
