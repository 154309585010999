import { ContextMenuProps } from "@torch-ai-internal/react-display-components/lib/components/Interaction/ContextMenu/ContextMenu";
import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  KnowledgeBaseGraphDomainCollectionState,
  KnowledgeBaseGraphLayerContents,
  KnowledgeBaseGraphLink,
  KnowledgeBaseGraphLinkType,
  KnowledgeBaseGraphStructureDomain,
  KnowledgeBaseGraphVertex,
  KnowledgeGraphContentsQueryHookResult,
  KnowledgeGraphDomainAuditLazyQueryHookResult,
  KnowledgeGraphStructureQueryHookResult,
  KnowledgeType,
  useKnowledgeGraphContentsLazyQuery,
  useKnowledgeGraphDomainAuditLazyQuery,
} from "../../../../../../generated/types";
import { getDomainColorRGB, WorkspaceContext } from "../../WorkspaceContext";

export interface Interface {
  /** Defines if the contents of the graph are loading, separate than the structure */
  areContentsLoading: boolean;
  /** Enables widgets that allow the user to sync their local data with updated data */
  areUpdatesAvailable: boolean;
  /** Controls the filter and controls blade across all views */
  controlsBladeIsOpen: boolean;
  /** Graph contents passed through the user defined filters */
  filteredContents: {
    vertexes: KnowledgeBaseGraphVertex[];
    links: KnowledgeBaseGraphLink[];
  };
  inspectIsBladeOpen: boolean;
  inspectBladeData: KnowledgeBaseGraphVertex | undefined;
  /** Determines if all data for display is loaded */
  isAllDataLoaded: boolean;
  minAlpha: number;
  viewMode: "force-graph" | "map";
  getVertexColor: (
    vertex: KnowledgeBaseGraphVertex,
    confidence: number
  ) => { r: number; g: number; b: number; a: number };
  getDomainOntologyById: (id: string) => string;
  graphAuditQuery: KnowledgeGraphDomainAuditLazyQueryHookResult[1] | undefined;
  graphContentsQuery: KnowledgeGraphContentsQueryHookResult | undefined;
  /** Structure query used for polling and comparison to local state */
  graphStructureQuery: KnowledgeGraphStructureQueryHookResult | undefined;
  /** Active state used for use interaction until they choose to load new results */
  graphStructure: KnowledgeGraphStructureQueryHookResult["data"] | undefined;
  /** Filters available to reduce the domains from view */
  selectedDomains: string[];
  /** Filters available to reduce the entities within a domain from view */
  selectedEntityTypes: string[];
  setSelectedEntityTypes: Dispatch<
    SetStateAction<Interface["selectedEntityTypes"]>
  >;
  selectedContextMenuItem: ContextMenuItemType | undefined;
  maxNumberOfEntityTypePerDomain: number;
  setSelectedContextMenuItem: Dispatch<
    SetStateAction<ContextMenuItemType | undefined>
  >;
  setAreUpdatesAvailable: Dispatch<
    SetStateAction<Interface["areUpdatesAvailable"]>
  >;
  setControlsBladeIsOpen: Dispatch<
    SetStateAction<Interface["controlsBladeIsOpen"]>
  >;
  setInspectIsBladeOpen: Dispatch<
    SetStateAction<Interface["inspectIsBladeOpen"]>
  >;
  setInspectBladeData: Dispatch<SetStateAction<Interface["inspectBladeData"]>>;
  setViewMode: Dispatch<SetStateAction<Interface["viewMode"]>>;
  setSelectedDomains: Dispatch<SetStateAction<Interface["selectedDomains"]>>;
  useAvailableUpdates: () => void;
}

export const VisualizationsContext = React.createContext<Interface>(
  {} as Interface
);
VisualizationsContext.displayName = "VisualizationsContext";

interface Props {}
export const Provider: React.FunctionComponent<Props> = ({ children }) => {
  const params = useMemo<URLSearchParams>(
    () => new URLSearchParams(window.location.search),
    []
  );

  const { graphStructureQuery } = useContext(WorkspaceContext);

  const [isAllDataLoaded, setIsAllDataLoaded] = useState(false);

  // Provides the data for loading the knowledge graph contents
  const [getInstitutionContent, institutionContentQuery] =
    useKnowledgeGraphContentsLazyQuery();

  // State
  const [controlsBladeIsOpen, setControlsBladeIsOpen] = useState(false);
  // Map or force graph?
  const [viewMode, setViewMode] =
    useState<Interface["viewMode"]>("force-graph");

  // Domains (The top level controls)
  const [selectedDomains, setSelectedDomains] = useState<
    Interface["selectedDomains"]
  >(params.get(domainsParameter)?.split(urlValuesSeparator) || []);
  // Entity types (under each domain)
  const [selectedEntityTypes, setSelectedEntityTypes] = useState<
    Interface["selectedEntityTypes"]
  >(params.get(entityTypesParameter)?.split(urlValuesSeparator) || []);

  // Stores the interactive state of the structure for the user
  // This allows us to compare the state of the domains, and notify the user
  // when a domain has completed about new available data.
  const [graphStructure, setGraphStructure] =
    useState<Interface["graphStructure"]>();
  const [areUpdatesAvailable, setAreUpdatesAvailable] = useState(false);

  // Used to track if we've gotten the root domain and set selected domains and entities in controls
  const [isInitialized, setIsInitialized] = useState(false);
  // Initialize the controls as soon as possible
  useEffect(() => {
    // We're already done
    if (isInitialized) {
      return;
    }

    graphStructureQuery.startPolling(2000);
    const availableDomains = getAggregateDomains(graphStructureQuery.data);

    if (!areRootDomainsLoaded(availableDomains)) {
      return;
    }

    const rootDomains = (
      graphStructureQuery.data?.knowledgeBase?.graphStructure?.institutions.flatMap(
        (institution) => institution.domains
      ) || []
    )?.filter(isDomainRoot);

    // console.info("graphStructureQuery.startPolling(2000);");
    // graphStructureQuery.startPolling(2000);

    initialize({
      graphStructure: graphStructureQuery.data,
      rootDomains: rootDomains,
      setGraphStructure,
      setIsInitialized,
      setSelectedDomains,
      setSelectedEntityTypes,
    });
  }, [
    isInitialized,
    graphStructureQuery,
    setGraphStructure,
    setIsInitialized,
    setSelectedDomains,
    setSelectedEntityTypes,
  ]);

  // TODO pretty sure this is F'd
  const useAvailableUpdates = useCallback(async () => {
    console.info("useAvailableUpdates");
    setAreUpdatesAvailable(false);

    // Update the cached structure vs what the structure query.
    // We use the query to track structure query to track updates, so it should not require a refresh.
    setGraphStructure(graphStructureQuery.data);
    // Fetch new contents of the molecule
    const query = institutionContentQuery;
    const results = await query.refetch();
    if (!results.data?.knowledgeBase?.graphContents) {
      setContents(initialContents);
      setAreContentsLoading(false);
      throw new Error("query.data?.knowledgeBase?.graphContents is undefined");
    }

    const { knowledgeType, links, vertexes } =
      results.data.knowledgeBase.graphContents;
    console.info("useAvailableUpdates setContents to results from", {
      knowledgeType,
    });
    setContents({
      knowledgeType,
      links,
      vertexes,
    });
  }, [graphStructureQuery, setAreUpdatesAvailable, institutionContentQuery]);

  // Watch for new available data
  useEffect(() => {
    if (
      // We don't need to tell the user that new data is available if we're still initializing
      !isInitialized ||
      // Or we already told the user
      areUpdatesAvailable
    ) {
      return;
    }

    const availableDomains = getAggregateDomains(graphStructureQuery.data);
    const currentDomains = getAggregateDomains(graphStructure);
    if (
      availableDomains.length > 0 &&
      areDomainUpdatesAvailable(currentDomains, availableDomains)
    ) {
      console.info("setAreUpdatesAvailable(true)");
      setAreUpdatesAvailable(true);
    }
  }, [
    isInitialized,
    areUpdatesAvailable,
    setAreUpdatesAvailable,
    graphStructure,
    graphStructureQuery,
  ]);

  // Track our loading progress
  useEffect(() => {
    if (!isInitialized || isAllDataLoaded) {
      return;
    }

    // Errors kill our progress
    if (graphStructureQuery.error) {
      console.info("graphStructureQuery.error stopped monitoring", {
        error: graphStructureQuery.error,
      });
      setIsAllDataLoaded(true);
      return;
    }

    // If there's no data, we can take no action
    if (!graphStructureQuery.data) {
      return;
    }

    const availableDomains = getAggregateDomains(graphStructureQuery.data);
    if (areAllDomainsLoaded(availableDomains)) {
      console.info(
        "setIsAllDataLoaded(true); graphStructureQuery.stopPolling();"
      );
      setIsAllDataLoaded(true);
      graphStructureQuery.stopPolling();
    }
  }, [isInitialized, isAllDataLoaded, graphStructureQuery, setIsAllDataLoaded]);

  // Map domain ids to their domain for faster access.
  type DomainIndex = Record<string, KnowledgeBaseGraphStructureDomain>;
  const [domainIndex, setDomainIndex] = useState<DomainIndex>({});
  useEffect(() => {
    const domainIndex: DomainIndex = {};
    graphStructure?.knowledgeBase?.graphStructure?.institutions.forEach(
      (institution) => {
        institution.domains.forEach((domain) => {
          if (!domain.id) {
            return;
          }

          domainIndex[domain.id] = domain;
        });
      }
    );

    setDomainIndex(domainIndex);
  }, [graphStructure, setDomainIndex]);

  const getDomainOntologyById: Interface["getDomainOntologyById"] = useCallback(
    (id) => {
      const ontology = domainIndex[id].ontology;
      if (!ontology) {
        throw new Error(`ontologyIdMap[${id}] is undefined`);
      }
      return ontology;
    },
    [domainIndex]
  );

  // Set the contents based on the view scope
  // TODO this costs some duplicate memory, and it's only so we can do useEffect below with less dependencies.
  const [contents, setContents] = useState<Contents>(initialContents);
  const [areContentsLoading, setAreContentsLoading] = useState(true);
  useEffect(() => {
    const id = graphStructureQuery.data?.knowledgeBase?.graphStructure?.id;
    if (!id) {
      return;
    }

    setAreContentsLoading(true);
    setContents(initialContents);
    const getData = () =>
      getInstitutionContent({
        variables: {
          id,
          knowledgeType: KnowledgeType.Institutions,
        },
      });

    console.info("Querying contents for institutions");

    getData().then((query) => {
      if (!query.data?.knowledgeBase?.graphContents) {
        setContents(initialContents);
        setAreContentsLoading(false);
        throw new Error(
          "query.data?.knowledgeBase?.graphContents is undefined"
        );
      }

      const { knowledgeType, links, vertexes } =
        query.data.knowledgeBase.graphContents;
      console.info("setContents to results from", { knowledgeType });
      setContents({
        knowledgeType,
        links,
        vertexes,
      });
    });
  }, [
    graphStructureQuery.data?.knowledgeBase?.graphStructure?.id,
    getInstitutionContent,
    setContents,
    setAreContentsLoading,
  ]);

  // Set the filtered data based on the contents and controls
  const [filteredContents, setFilteredContents] = useState<
    Interface["filteredContents"]
  >(initialFilteredContents);
  useEffect(() => {
    if (!isInitialized) {
      return;
    }

    const { vertexes, links } = contents;

    // Remove any vertexes we don't need to display.
    const filteredVertexes: KnowledgeBaseGraphVertex[] = vertexes!.filter(
      ({ domain: domainId, entityType }) => {
        const { ontology } = domainIndex[domainId];
        const filterString = ontology || "";
        return (
          // Filter out vertexes by domain.
          selectedDomains.includes(filterString) &&
          // Filter out vertexes by entity type.
          selectedEntityTypes.includes(`${filterString}/${entityType}`)
        );
      }
    );

    // Get the ids of all the available vertexes to filter out links.
    const filteredVertexIds = filteredVertexes.map((vertex) => vertex.id);
    const filteredLinks: KnowledgeBaseGraphLink[] = links!.filter(
      (l: KnowledgeBaseGraphLink) =>
        filteredVertexIds.includes(l.to) &&
        filteredVertexIds.includes(l.from) &&
        // Also, remove revision links.
        l.type !== KnowledgeBaseGraphLinkType.Revision
    );

    // Perform a final filter on the vertexes, removing any that aren't linked `to` (except for the email message).
    const linksToVisibleVertexes: string[] = filteredLinks.map(
      (link) => link.to
    );
    const visibleVertexes = filteredVertexes.filter((vertex) => true);

    console.info("setFilteredVertexes", {
      vertexes: visibleVertexes,
      links: filteredLinks,
    });

    setFilteredContents({
      vertexes: visibleVertexes,
      links: filteredLinks,
    });
    setAreContentsLoading(false);
  }, [
    isInitialized,
    selectedDomains,
    selectedEntityTypes,
    contents,
    domainIndex,
    setFilteredContents,
    setAreContentsLoading,
  ]);

  // Gets the base color from a vertex's domain and adds alpha from a supplied confidence.
  const getVertexColor = useCallback(
    (
      { domain: id }: KnowledgeBaseGraphVertex,
      confidence: number
    ): ReturnType<Interface["getVertexColor"]> => {
      const { ontology } = domainIndex[id];
      if (!ontology) {
        return {
          ...getDomainColorRGB("other"),
          a: confidence,
        };
      }

      const adjustedConfidence = confidence * (1 - minAlpha) + minAlpha;
      return {
        ...getDomainColorRGB(ontology),
        a: adjustedConfidence,
      };
    },
    [domainIndex]
  );

  // Data inspection
  const [selectedContextMenuItem, setSelectedContextMenuItem] =
    useState<Interface["selectedContextMenuItem"]>();
  const [inspectIsBladeOpen, setInspectIsBladeOpen] = useState(false);
  const [inspectBladeData, setInspectBladeData] = useState<
    KnowledgeBaseGraphVertex | undefined
  >();
  const [getGraphAudit, graphAuditQuery] =
    useKnowledgeGraphDomainAuditLazyQuery();
  // After inspection through context menu, keep the audit data in sync with the item
  useEffect(() => {
    if (!inspectBladeData?.attribution || !inspectBladeData?.domain) {
      return;
    }
    const { attribution, domain } = inspectBladeData;
    getGraphAudit({
      variables: {
        attribution,
        domain,
      },
    }).then();
  }, [inspectBladeData, getGraphAudit]);

  return (
    <VisualizationsContext.Provider
      value={{
        areContentsLoading,
        areUpdatesAvailable,
        controlsBladeIsOpen,
        filteredContents,
        inspectIsBladeOpen,
        inspectBladeData,
        minAlpha,
        graphAuditQuery,
        graphContentsQuery: institutionContentQuery,
        graphStructureQuery,
        getDomainOntologyById,
        getVertexColor,
        graphStructure,
        isAllDataLoaded,
        selectedDomains,
        selectedEntityTypes,
        selectedContextMenuItem,
        setSelectedContextMenuItem,
        setAreUpdatesAvailable,
        setControlsBladeIsOpen,
        setInspectIsBladeOpen,
        setInspectBladeData,
        setSelectedDomains,
        setSelectedEntityTypes,
        setViewMode,
        viewMode,
        maxNumberOfEntityTypePerDomain,
        useAvailableUpdates,
      }}
    >
      {children}
    </VisualizationsContext.Provider>
  );
};

const maxNumberOfEntityTypePerDomain = 100;
const minAlpha = 0.4;
const domainsParameter = "domains";
const entityTypesParameter = "entity-types";
const urlValuesSeparator = ",";
type Contents = Pick<
  KnowledgeBaseGraphLayerContents,
  "knowledgeType" | "links" | "vertexes"
>;
const initialContents: Contents = {
  knowledgeType: KnowledgeType.Persons,
  links: [],
  vertexes: [],
};

const initialFilteredContents: Interface["filteredContents"] = {
  vertexes: [],
  links: [],
};

export type ContextMenuItemType = {
  item?: any;
  position: ContextMenuProps["position"];
};

const getAggregateDomains = (
  graphStructure: KnowledgeGraphStructureQueryHookResult["data"]
): KnowledgeBaseGraphStructureDomain[] => {
  if (!graphStructure) {
    return [];
  }

  const institutions =
    graphStructure.knowledgeBase?.graphStructure?.institutions || [];

  return [...institutions.flatMap((institution) => institution.domains)];
};

const areRootDomainsLoaded = (
  domains: KnowledgeBaseGraphStructureDomain[] | undefined
): boolean => {
  if (!domains?.length) {
    return false;
  }

  return areAllDomainsLoaded(domains.filter(isDomainRoot));
};

const areAllDomainsLoaded = (
  domains: KnowledgeBaseGraphStructureDomain[] | undefined
): boolean => {
  if (!domains?.length) {
    return false;
  }

  const { Completed, Error } = KnowledgeBaseGraphDomainCollectionState;
  return (
    domains.filter(({ status }) => status === Completed || status === Error)
      .length === domains.length
  );
};

const areDomainUpdatesAvailable = (
  currentDomains: KnowledgeBaseGraphStructureDomain[] | undefined,
  availableDomains: KnowledgeBaseGraphStructureDomain[] | undefined
): boolean => {
  if (!availableDomains?.length) {
    return false;
  }

  // Create a hash map of the current statuses for efficient access
  type StatusMap = Record<
    KnowledgeBaseGraphStructureDomain["id"],
    KnowledgeBaseGraphStructureDomain["status"]
  >;
  const currentStatuses: StatusMap = (currentDomains || []).reduce(
    (record, { id, status }) => {
      record[id] = status;
      return record;
    },
    {} as StatusMap
  );

  return (
    availableDomains.filter(
      (domain) => domain.status !== currentStatuses[domain.id]
    ).length > 0
  );
};

type Initialize = (parameters: {
  rootDomains: KnowledgeBaseGraphStructureDomain[];
  graphStructure: Interface["graphStructure"];
  setGraphStructure: Dispatch<SetStateAction<Interface["graphStructure"]>>;
  setIsInitialized: Dispatch<SetStateAction<boolean>>;
  setSelectedEntityTypes: Interface["setSelectedEntityTypes"];
  setSelectedDomains: Interface["setSelectedDomains"];
}) => void;
const initialize: Initialize = ({
  rootDomains,
  graphStructure,
  setGraphStructure,
  setIsInitialized,
  setSelectedEntityTypes,
  setSelectedDomains,
}) => {
  console.info("initialize");
  setIsInitialized(true);
  setGraphStructure(graphStructure);

  const selectedDomains: Interface["selectedDomains"] = rootDomains.map(
    (domain) => domain.ontology || ""
  );
  setSelectedDomains(selectedDomains);

  const allDomainEntityTypes: Interface["selectedEntityTypes"] = rootDomains
    ?.map((domain) =>
      (domain.entityTypes || []).map(
        (entityType) => `${domain.ontology}/${entityType!.displayName}`
      )
    )
    .flat();
  setSelectedEntityTypes(allDomainEntityTypes);
};

// TODO find a way not to make this hard coded using a prop from services
const isDomainRoot = (domain: KnowledgeBaseGraphStructureDomain) =>
  domain.ontology === "Email";
