import React, { useCallback, useEffect } from "react";
import { SelfQueryHookResult, useSelfQuery } from "../generated/types";
import AuthService from "../services/auth.service";

export interface IUserContext {
  userQuery: SelfQueryHookResult;
  logout(): Promise<void>;
  authenticationExpired(): Promise<void>;
}

const UserContext = React.createContext<IUserContext>({} as IUserContext);
UserContext.displayName = "UserContext";
export default UserContext;

const authService = new AuthService();

interface Props {}
export const UserContextProvider = (({ children }) => {
  const userQuery = useSelfQuery({});

  const setUserFromAuthService = useCallback(async () => {
    const user = await authService.getUser();
    if (!user) {
      return await authService.login();
    }
  }, []);

  const logout = async () => {
    await authService.logout();
  };

  const authenticationExpired = async () => {
    await authService.login();
  };

  useEffect(() => {
    // noinspection JSIgnoredPromiseFromCall
    setUserFromAuthService();
  }, [setUserFromAuthService]);

  const context: IUserContext = {
    userQuery,
    logout,
    authenticationExpired,
  };

  return (
    <UserContext.Provider value={context}>{children}</UserContext.Provider>
  );
}) as React.FunctionComponent<Props>;
