import React from "react";
import { Outlet } from "react-router-dom";
import { Provider as VisualizationsContextProvider } from "../../components/Visualizations/VisualizationsContext";

interface Props {}

const VisualizationsRouter: React.FunctionComponent<Props> = () => {
  return (
    <VisualizationsContextProvider>
      <Outlet />
    </VisualizationsContextProvider>
  );
};

export default VisualizationsRouter;
