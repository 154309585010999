import React from "react";
import { HelmetProvider } from "react-helmet-async";
import { ThemesContextProvider } from "../../../contexts/ThemesContext";
import { AppContextProvider } from "../../../contexts/AppContext";
import { UserContextProvider } from "../../../contexts/UserContext";
import { Provider as RecentKnowledgeContext } from "../../../contexts/RecentKnowledgeContext";
import { BrowserRouter as Router } from "react-router-dom";
import torchOrangeLight from "@torch-ai-internal/react-display-components/lib/themes/torchOrangeLight";
import { ApolloProvider } from "@apollo/client";
import { apolloClient } from "../../../services/apollo";
import { Provider as MapsContextProvider } from "@torch-ai-internal/react-mapbox-components/lib/components/Maps/MapsContextProvider/MapsContextProvider";
import { Provider as ChartsContextProvider } from "@torch-ai-internal/react-amcharts-components/lib/components/Charts/ChartsContextProvider/ChartsContextProvider";

const Providers = (({ children }) => {
  const isReducedMotionPreferred = window.matchMedia(
    "(prefers-reduced-motion: reduce)"
  ).matches;

  return (
    <Router>
      {/*This needs to be kept inside <Router /> to control redirection for authentication*/}
      <ApolloProvider client={apolloClient}>
        <AppContextProvider>
          <ThemesContextProvider themes={[torchOrangeLight]}>
            <RecentKnowledgeContext>
              <MapsContextProvider apiKey={process.env.REACT_APP_MAPBOX_KEY}>
                <ChartsContextProvider
                  useAnimations={!isReducedMotionPreferred}
                  license={process.env.REACT_APP_AMCHARTS_LICENSE}
                >
                  <UserContextProvider>
                    <HelmetProvider>{children}</HelmetProvider>
                  </UserContextProvider>
                </ChartsContextProvider>
              </MapsContextProvider>
            </RecentKnowledgeContext>
          </ThemesContextProvider>
        </AppContextProvider>
      </ApolloProvider>
    </Router>
  );
}) as React.FunctionComponent;
export default Providers;
